<template>
  <div class="finish-payment-wrapper">
    <template v-if="userInfo && userInfo.isTemporary">
      <div class="finish-payment__title">
        Оплата произведена успешно
      </div>
      <div class="finish-payment__description">
        Письмо с активацией отправлено на вашу почту
      </div>
      <div class="finish-payment__email-text">
        {{userInfo.email}}
      </div>
      <div class="finish-payment__instruction">
        <div class="finish-payment__instruction-item">
          <div class="finish-payment__instruction-number">1</div>
          <div class="finish-payment__instruction-text">
            Откройте Вашу почту
            и найдите письмо активации
            <span class="finish-payment__instruction-text--fw-600">“Активация пользователя AYA school”</span>
          </div>
        </div>
        <div class="finish-payment__instruction-item">
          <div class="finish-payment__instruction-number">2</div>
          <div class="finish-payment__instruction-text">
            Активируйте свой аккаунт
            по ссылке из письма, отправленного на указанную почту
          </div>
        </div>
        <div class="finish-payment__instruction-item">
          <div class="finish-payment__instruction-number">3</div>
          <div class="finish-payment__instruction-text">
            Если вы ошиблись
            при вводе e-mail или
            не получили письмо активации, то обратитесь
            в
            <a>службу поддержки</a>
          </div>
        </div>
      </div>
    </template>
    <div v-else-if="userInfo && !userInfo.isTemporary || isLoggedIn" class="finish-wrapper__content">
      <div class="finish-wrapper">
        <div class="finish-wrapper__icon">
          <img src="/img/payment/icon-check.svg" alt="">
        </div>
        <div class="finish-wrapper__title">Спасибо!</div>
        <div class="finish-wrapper__description" :style="{'margin-bottom': isLoggedIn ? '73px':'32px'}">
          Оплата произведена успешно
        </div>
        <div v-if="!isLoggedIn" class="finish-wrapper__info">
          Спасибо за покупку курса, войдите в ваш аккаунт, чтобы получить к нему доступ
        </div>
        <a v-if="isLoggedIn" href="/my-courses" class="finish-wrapper__button">Вернуться к моим курсам</a>
        <a v-else href="/login" class="finish-wrapper__button" style="width: 240px">Войти</a>
        <div v-if="isLoggedIn" class="finish-wrapper__prescription">
          После обработки платежа курс
          появится на странице
          <a href="/my-courses">Мои курсы</a>
        </div>
      </div>
    </div>

    <img class="finish-payment__ui-left" src="@/assets/img/user/payment/element-ui-left.svg" alt="">
    <img class="finish-payment__ui-right" src="@/assets/img/user/payment/element-ui-right.svg" alt="">
    <img class="finish-payment__icon-online" src="@/assets/img/user/payment/icon-online.svg" alt="">
    <img class="finish-payment__icon-pen" src="@/assets/img/user/payment/icon-pen.svg" alt="">
  </div>
</template>

<script>

export default {
  name: "FinishPayment",
  data() {
    return {}

  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn
    },
    userInfo() {
      return JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    if(this.userInfo && !this.userInfo.isTemporary)
      this.$store.commit('setFlagNewUser', true)

    window.ym(process.env.VUE_APP_YM_ID,'reachGoal','successful-purchase')
  }
}
</script>

<style lang="scss" scoped>
@import "/style/finish.css";

.finish-payment {
  &-wrapper {
    margin-top: 62px;
    min-height: 460px;
    padding: 48px 84px;
    background: rgba(20, 84, 242, 0.02);
    border-radius: 20px;

    transition: 0.2s;
    @media screen and (max-width: 980px) {
      padding: 36px 24px;
      margin: 16px 24px 0 24px;
    }
    @media screen and (max-width: 450px) {
      padding: 20px 16px;
      margin: 16px 16px 0 16px;
    }
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;

    width: 240px;
    margin: auto;
    transition: 0.2s;
  }

  &__description {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    margin-top: 32px;
  }

  &__email-text {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: center;
    color: #1454F2;
    margin-top: 4px;
    margin-bottom: 48px;
    word-break: break-all;
  }

  &__instruction {
    display: flex;
    justify-content: space-between;

    transition: 0.2s;

    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }

  &__instruction-item {
    width: 267px;
    box-sizing: border-box;
    padding-right: 16px;

    transition: 0.2s;

    @media screen and (max-width: 700px) {
      width: 100%;
      margin-bottom: 32px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__instruction-number {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;

    margin-bottom: 8px;

    border-radius: 100%;
    background: #0F0F0F;
  }

  &__instruction-text {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #000000;

    &--fw-600 {
      font-weight: 500;
    }

    > a {
      text-decoration: none;
      color: #1454F2;
      border-bottom: 1px solid #1454F2;
    }
  }

  @media screen and (max-width: 450px) {
    &__title {
      font-size: 18px;
    }
    &__email-text {
      margin-bottom: 32px;
    }
    &__description {
      max-width: 256px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 1800px) {
    &__icon-pen,
    &__icon-online,
    &__ui-left,
    &__ui-right {
      display: none;
    }
  }

  &__icon-pen {
    position: absolute;
    right: 215px;
    top: 60%;
  }
  &__icon-online {
    position: absolute;
    left: 110px;
    top: 220px;
  }
  &__ui-left {
    position: absolute;
    left: 0;
    bottom: 300px;
    z-index: -1;
  }
  &__ui-right {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: -1;
  }
}

</style>